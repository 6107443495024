import React, { useEffect } from 'react';
import { Box, CheckboxProps, Divider, InputAdornment, SxProps, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SchoolsIcon from '../Icons/SchoolsIcon';
import { palette } from '../../theme/palette';
import { svgStyles } from '../Common/Common.styled';
import useTeacherStudentGradesFilter from './teachergrade-hook';
import { GradeDto } from '../../generated/graphql';

interface Props {
  error?: boolean;
  value?: string[] | null;
  onChange?: (data: any[]) => void;
  placeholder?: string;
  disabled?: boolean;
  errorMessage?: any;
  limitTags?: number;
  isStartAdornment?: boolean;
  hideGroups?: boolean,
  schoolId?: string | null,
  zIndexPopper?: number;
}

const styles = {
  textField: {
    border: '0px',
    minHeight: '48px',
    height: 'auto',
    '& input.MuiInputBase-input.Mui-disabled': {
      backgroundColor: palette.customWhite.inputDisabled,
    },
  },
  box: {
    backgroundColor: palette.customWhite.main,
    height: '45px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
};
type GradeValue = GradeDto | null | undefined;
const MultiSelectTeacherGradeFilter = ({
  placeholder = '',
  onChange,
  value,
  errorMessage,
  disabled,
  limitTags = 2,
  isStartAdornment = true,
  zIndexPopper = 1300,
}: Props) => {

  const allGradesOption: any = {
    id: 'all',
    grade: 'Select All',

  };
  const { grades } = useTeacherStudentGradesFilter();

  const gradeOptions: any[] = grades?.length ? [allGradesOption, ...grades] : grades;
  const [checkAll, setCheckAll] = React.useState(false);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const onSelectChange = (_: any, gradeList: GradeValue[] | undefined = []) => {
    const gradeListIds = gradeList?.map((gradeOption: GradeValue) => gradeOption?.id);
    onChange?.(gradeListIds as string[] || []);
  };
  const filterIdsWithGrades = (selectedValue: any[] | undefined | null) => {
    const gradeIds = grades?.map((gradeOption: any) => gradeOption.id) ?? [];
    const filteredIds = selectedValue?.filter((id) => gradeIds.includes(id)) ?? [];
    const gradeList = grades?.filter((gradeOption) => filteredIds.includes(gradeOption?.id));
    if (selectedValue?.length !== filteredIds.length && grades) onChange?.(gradeList?.map((gradeOption: any) => gradeOption?.id) ?? []);
    if (selectedValue?.length === 0 || selectedValue?.length !== grades?.length) {
      setCheckAll(false);
    } else if (selectedValue?.length === grades?.length) {
      setCheckAll(true);
    }
  };
  useEffect(() => {
    filterIdsWithGrades(value);
  }, [value]);

  const checkAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckAll(event.target.checked);
    if (event.target.checked) {
      onChange?.(grades?.map((gradeOption) => gradeOption?.id) as string[]);
    } else {
      onChange?.([]);
    }
  };
  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      limitTags={limitTags}
      options={gradeOptions ?? []}
      getOptionLabel={(option: GradeValue) => `${option?.grade ?? ''}`}
      isOptionEqualToValue={(option: GradeValue, selected: GradeValue) => option?.id === selected?.id}
      onChange={onSelectChange}
      slotProps={{ popper: { sx: { zIndex: zIndexPopper } } }}
      value={grades?.filter((gradeOption: GradeValue) => value?.includes(gradeOption?.id!)) ?? []}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Box sx={{ maxWidth: '120px' }}>
            <Chip
              label={<Typography sx={{ fontWeight: 800, fontSize: '13px' }} component='span'>{option?.grade ?? ''}</Typography>}
              {...getTagProps({ index })}
            />
          </Box>
        ))
      }
      renderOption={(props, option: any, { selected }) => {


        return (
          option?.id === 'all' ? (
            <>
              <Box sx={{ ...styles.box } as SxProps} key={option?.id}>
                <Checkbox
                  checked={checkAll}
                  onChange={checkAllChange}
                  id="check-all"
                  onMouseDown={(e) => e.preventDefault()}
                />
              Select All
              </Box>
              <Divider />
            </>
          ) : <Box component="li" {...props} key={option?.id}>
            <Checkbox
              color={'primary' as CheckboxProps['color']}
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
              sx={{ mr: '8px' }}
            />
            {`${option?.grade ?? ''}`}
          </Box>
        );
      }}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            sx={{ ...styles.textField }}
            placeholder={params.InputProps.startAdornment ? undefined : placeholder.length > 1 ? placeholder : 'Select Grades'}
            error={!!value}
            helperText={value?.length === 0 ? errorMessage?.schoolHelperText : ''}
            required={value?.length === 0}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: { height: 'auto', maxHeight: '300px', overflow: 'scroll', fontSize: '16px', background: palette.customWhite.main },
              startAdornment: isStartAdornment ? (
                <>
                  <InputAdornment position="start">
                    <SchoolsIcon
                      pathfill={palette.customBlue.primaryBlue}
                      sx={{ ...svgStyles.icon }} />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ) : <>{params.InputProps.startAdornment}</>,
            }}
          />
        </>
      )}
    /> 
  );
};

export default MultiSelectTeacherGradeFilter;
