import { createContext, useContext, useEffect, useState } from 'react';
import {
  BenchmarkType,
  OrderBy,
  TeacherBenchmarkStudentListSortType,
  useGetLastSnowflakeUpdateTimeQuery,
  useTeacherBenchmarkStudentListQuery,
} from '../../../generated/graphql';
import getErrorMessage from '../../../utils/getErrorMessage';
import { openSnackbar } from '../../../components/Notifier';
import { NotifierType } from '../../../variables/types';
import { TeacherStudentValue } from '../../../components/SelectTeacherStudentFilter';
import { useHistory, useParams } from 'react-router-dom';
import { RouteParamProp } from '../../AdminBenchmarks/Edit/benchmark-detail-hook';
import { DashboardWidgetsDataStatusProps } from '../../AdminAnalytics/admin-analytics-hook';
import { StringParam, useQueryParam } from 'use-query-params';
import { StudentBenchmarkDashBoard as StudentBenchmarkDashboardConstant } from '../../../variables/constant';


export interface IndividualStudentBenchmarksDashboardContextValue {
  areWidgetsLoading?: boolean,
  setAreWidgetsLoading?: (value: boolean) => void,
  dashboardWidgetsDataStatus?: DashboardWidgetsDataStatusProps[],
  setDashboardWidgetsDataStatus?: Function,
  isDataMissingInAllWidgets?: boolean,
  setIsDataMissingInAllWidgets?: Function,
  loading?: boolean,
  setLoading?: (value: boolean) => void,
}
export const IndividualStudentBenchmarksDashboardContext = createContext<IndividualStudentBenchmarksDashboardContextValue>({

});

export const IndividualStudentBenchmarksDashboardContextInit = ({
  dashboardWidgets,
}: any) => {

  const [loading, setLoading] = useState(false);
  const [dashboardWidgetsDataStatus, setDashboardWidgetsDataStatus] = useState<DashboardWidgetsDataStatusProps[]>(
    Object.values(dashboardWidgets).map((widgetData: any) => ({ widget: widgetData.name, loading: true, dataMissing: false })),
  );
  const [isDataMissingInAllWidgets, setIsDataMissingInAllWidgets] = useState(false);
  const [areWidgetsLoading, setAreWidgetsLoading] = useState(true);

  return ({
    loading, setLoading,
    dashboardWidgetsDataStatus, setDashboardWidgetsDataStatus,
    isDataMissingInAllWidgets, setIsDataMissingInAllWidgets,
    areWidgetsLoading, setAreWidgetsLoading,
  });
};

export const useIndividualStudentBenchmarksDashboardContext = () => {
  const {
    areWidgetsLoading,
    setAreWidgetsLoading,
    dashboardWidgetsDataStatus,
    setDashboardWidgetsDataStatus,
    isDataMissingInAllWidgets,
    setIsDataMissingInAllWidgets,
    loading,
    setLoading,
  } = useContext(IndividualStudentBenchmarksDashboardContext);
  const checkDataMissingInAllWidgets = () => dashboardWidgetsDataStatus?.map(widgetData => widgetData.dataMissing).every(dataMissing => dataMissing);
  const checkIfAnyWidgetStillLoading = () => dashboardWidgetsDataStatus?.map(widgetData => widgetData.loading).some(flag => flag === true);
  const updateDashboardWidgetsDataStatus = ({ widget, loading: widgetLoading, dataMissing }: DashboardWidgetsDataStatusProps) => {
    setDashboardWidgetsDataStatus?.(dashboardWidgetsDataStatus?.map((dashboardWidget) => (
      dashboardWidget.widget !== widget ? dashboardWidget : { widget, loading: widgetLoading, dataMissing: dataMissing ?? dashboardWidget.dataMissing }
    )));
  };
  // resets the data flags of all widgets before the new set of requests due to new filters or user actions.
  const resetDashboardWidgetsDataStatus = (widgetLoading: boolean, widgetDataMissing?: boolean) => {
    const newDashboardWidgetsDataStatus: DashboardWidgetsDataStatusProps[] = [];
    dashboardWidgetsDataStatus?.forEach(widgetDataStatus => {
      let newWidgetDataStatus: any = {};
      if (widgetLoading !== undefined) newWidgetDataStatus = { loading: widgetLoading, ...newWidgetDataStatus };
      if (widgetDataMissing !== undefined) newWidgetDataStatus = { dataMissing: widgetDataMissing, ...newWidgetDataStatus };
      newDashboardWidgetsDataStatus.push({
        ...widgetDataStatus,
        ...newWidgetDataStatus,
      });
    });
    setDashboardWidgetsDataStatus?.([...newDashboardWidgetsDataStatus]);
  };

  // update isDataMissingInAllWidgets when there is change in the data-Status in each widget
  useEffect(() => {
    setIsDataMissingInAllWidgets?.(checkDataMissingInAllWidgets());
    setAreWidgetsLoading?.(checkIfAnyWidgetStillLoading() || false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardWidgetsDataStatus]);


  return {
    areWidgetsLoading, setAreWidgetsLoading,
    dashboardWidgetsDataStatus, setDashboardWidgetsDataStatus,
    isDataMissingInAllWidgets, setIsDataMissingInAllWidgets,
    loading, setLoading, updateDashboardWidgetsDataStatus,
    resetDashboardWidgetsDataStatus,
  };
};
const useStudentBenchmarksDashboard = ({ setDashboardWidgetsDataStatus, dashboardWidgetsDataStatus }: any) => {
  const { id } = useParams<RouteParamProp>();
  const [benchmarkTypeParam] = useQueryParam('benchmarkType', StringParam);
  const defaultBenchmarkType = benchmarkTypeParam ? benchmarkTypeParam as BenchmarkType : BenchmarkType.Boy;
  const history = useHistory<RouteParamProp>();
  const [studentData, setStudentData] = useState<TeacherStudentValue>();
  const [studentList, setStudentList] = useState<any>([]);
  const { data: lastSnowflakeUpdateTimeData, loading: getLastSnowflakeUpdateTimeLoading } = useGetLastSnowflakeUpdateTimeQuery({
    fetchPolicy: 'network-only',
  });
  const [selectedBenchmarkType, setSelectedBenchmarkType] = useState<BenchmarkType>(defaultBenchmarkType);
  const getTabBasedOnDefaultBenchmarkType = (benchmarkType: BenchmarkType) => {
    switch (benchmarkType) {
      case BenchmarkType.Boy:
        return 0;
      case BenchmarkType.Moy:
        return 1;
      case BenchmarkType.Eoy:
        return 2;
      default:
        return 0;
    }
  };

  const [tab, setTab] = useState<number>(getTabBasedOnDefaultBenchmarkType(defaultBenchmarkType));
  const handleSelectedBenchmarkTypes = (_: React.ChangeEvent<{}>, newValue: number) => {
    const currentUrl = new URL(window.location.href);
    setTab(newValue);
    switch (newValue) {
      case 0: setSelectedBenchmarkType?.(BenchmarkType.Boy);
        currentUrl.searchParams.set('benchmarkType', BenchmarkType.Boy);
        break;
      case 1:
        setSelectedBenchmarkType?.(BenchmarkType.Moy);
        currentUrl.searchParams.set('benchmarkType', BenchmarkType.Moy);
        break;
      case 2:
        setSelectedBenchmarkType?.(BenchmarkType.Eoy);
        currentUrl.searchParams.set('benchmarkType', BenchmarkType.Eoy);
        break;

      default:
        setSelectedBenchmarkType?.(BenchmarkType.Boy);
        currentUrl.searchParams.set('benchmarkType', BenchmarkType.Boy);
        break;
    }
    window.history.replaceState({}, '', currentUrl.toString());

  };

  useEffect(() => {
    const newDashboardWidgetsDataStatus: DashboardWidgetsDataStatusProps[] = [];
    dashboardWidgetsDataStatus?.forEach((widgetDataStatus: DashboardWidgetsDataStatusProps) => {
      if ([StudentBenchmarkDashboardConstant.SpeakingBenchmarkIndicatorPerformaneWidget.name, StudentBenchmarkDashboardConstant.WritingBenchmarkIndicatorPerformaneWidget.name].includes(widgetDataStatus.widget)) {
        newDashboardWidgetsDataStatus.push({
          ...widgetDataStatus,
          loading: true,
          dataMissing: false,
        });
      } else {
        newDashboardWidgetsDataStatus.push(widgetDataStatus);
      }
    });
    setDashboardWidgetsDataStatus?.(newDashboardWidgetsDataStatus);
  }, [selectedBenchmarkType]);
  const {
    data: studentListData,
    loading: studentListDataLoader,
    error: inProgressGqlErr,
  } = useTeacherBenchmarkStudentListQuery(
    {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          sort_by: TeacherBenchmarkStudentListSortType.StudentFullName,
          order_by: OrderBy.Asc,
        },
      },
    });

  useEffect(() => {
    if (inProgressGqlErr) {
      openSnackbar({ message: getErrorMessage(inProgressGqlErr) }, NotifierType.Error);
    }

    const students: TeacherStudentValue[] = [];
    let selectedStudent: TeacherStudentValue = { id: '', name: '' };
    if (!studentListDataLoader) {
      studentListData?.teacherBenchmarkStudentList?.forEach((item: any) => {
        const studentName : string = item?.student_first_name.concat(' ').concat(item?.student_last_name) ?? '';
        students.push({
          id: item?.student_user_id,
          name: studentName,
        });
        if (item?.student_user_id === id) {
          selectedStudent.id = item?.student_user_id;
          selectedStudent.name = studentName;
        }
      });

      setStudentData(selectedStudent || students?.[0]);
      setStudentList(students);
    }
  }, [studentListData, studentListDataLoader, id, inProgressGqlErr]);

  const onStudentChange = (studentInfo: TeacherStudentValue) => {
    if (studentInfo.id !== studentData?.id) {
      setDashboardWidgetsDataStatus?.(Object.values(StudentBenchmarkDashboardConstant).map((widgetData: any) => ({ widget: widgetData.name, loading: true, dataMissing: false })));
    }
    setStudentData(studentInfo);
    const currentUrl = new URL(window.location.href);
    if (id) {
      const newUrlPathname = currentUrl.pathname.replace(id, studentInfo.id);
      currentUrl.pathname = newUrlPathname;
      currentUrl.searchParams.set('benchmarkType', selectedBenchmarkType);
      history.replace(newUrlPathname + currentUrl.search);
    }

  };



  return {
    studentData, setStudentData,
    lastSnowflakeUpdateTimeData: lastSnowflakeUpdateTimeData?.getLastSnowflakeUpdateTime,
    getLastSnowflakeUpdateTimeLoading,
    onStudentChange,
    selectedBenchmarkType, setSelectedBenchmarkType,
    tab, setTab, handleSelectedBenchmarkTypes,
    studentList,
    studentListDataLoader,
  };
};
export default useStudentBenchmarksDashboard;