import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import useBenchmarkSummary from './benchmarksummary-hooks';
import { BenchmarkType } from '../../../variables/types';
import { StyledTab, StyledTabs } from './benchmarksummary.styled';
import WelcomeDashboardModal from '../WelcomeDashboardModal';
import OverallBenchamarkIndicatorScores from './OverallBenchmarkIndicatorScores';
import OverallPerformanceWidget from './OverallPerformanceWidget';
import SubmissionStatus from './Submission';
import { TeacherBenchmarkDashBoard } from '../../../variables/constant';

const BenchmarkSummary = () => {
  const { tab, handleSelectedBenchmarkTypes, selectedClasses, selectedGrades, selectedSchools, selectedBenchmarkType, isFilterSelected } = useBenchmarkSummary();
  return (
    <Box width="100%" sx={{ backgroundColor: "#F7F7F7" }}>
      <Grid container spacing={2} sx={{ position: 'relative', }} pl={4} pr={4}  >
        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between', mt: 6, mb: 3 }}>
          <Typography variant='font24' sx={{ marginLeft: '22px' }}>Choose 1 Benchmark Type for Information Below</Typography>
          <Box sx={{ ml: 2, mr: -1 }}>
            <StyledTabs
              value={tab}
              onChange={handleSelectedBenchmarkTypes}
              textColor="primary"
              scrollButtons={false}
              id="benchmark-type-tabs"
            >
              {
                Object.entries(BenchmarkType).map(([tabLabel, tabKey]) => {
                  return (
                    <StyledTab
                      id={`${tabKey as string}-tab`}
                      data-click-id={`${tabKey as string}-tabs`}
                      key={`${tabKey as string}-tab`}
                      classes={{
                        root: `${tabKey as string}`,
                      }}
                      label={(
                        <Typography>
                          <Typography component="span" variant='title18'>
                            {tabLabel as string}
                          </Typography>
                        </Typography>
                      )}

                      disableRipple
                    />
                  );
                })
              }
            </StyledTabs>
          </Box>
        </Box>
        <Box
          display="grid"
          sx={{
            gridGap: 24,
            width: '100%',
          }}
          mb={2}
          ml={2}
          gridTemplateColumns="7fr 3fr"
        >
          <Grid item>
            <OverallPerformanceWidget
              enableTooltip
              alignTitle
              tooltipTitle={TeacherBenchmarkDashBoard.BenchmarkSummary.OverallPerformanceWidget.tooltipText}
              benchmarkType={selectedBenchmarkType}
            />
          </Grid>
          <SubmissionStatus
            enableTooltip
            alignTitle
            tooltipTitle={TeacherBenchmarkDashBoard.BenchmarkSummary.SubmissionStatusWidget.tooltipText}
            benchmarkType={selectedBenchmarkType} />
          <WelcomeDashboardModal openModal={!isFilterSelected} />
        </Box>
        <Box
          display="grid"
          sx={{
            gridGap: 24,
            width: '100%',
          }}


          mb={4}
          ml={2}
          gridTemplateColumns="1fr"
        >
          <OverallBenchamarkIndicatorScores
            enableTooltip
            alignTitle
            tooltipTitle={TeacherBenchmarkDashBoard.BenchmarkSummary.OverallBenchmarkIndicatorWidget.tooltipText}
            benchmarkType={selectedBenchmarkType} />
        </Box>  

      </Grid>
    </Box>

  );
};
export default BenchmarkSummary;