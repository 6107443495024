import React from 'react';
import Layout from '../../../components/Layout';
import { Box, Grid, SxProps, Typography, useTheme } from '@mui/material';
import useStudentBenchmarksDashboard, {
  IndividualStudentBenchmarksDashboardContext,
  IndividualStudentBenchmarksDashboardContextInit,
} from './individual-student-dashboard-hook';
import RoutingPaths from '../../RoutingPath';
import { StyledTabs, TeacherBenchmarkDashboardStylesCSS } from '../teacherbenchmarkdashboard.styled';
import Loader from '../../../components/Loader/loader';
import AppBreadcrumbs from '../../../components/AppBreadcrumbs';
import { toReadableFormat } from '../../../utils/dateFormat';
import { palette } from '../../../theme/palette';
import ExportToPdf from './pdfExport';
import StudentGrowthReportWidget from './StudentGrowthReportWidget';
import BenchmarkScoresWidget from './BenchmarkScoresWidget';
import { StudentBenchmarkDashBoard as StudentBenchmarkDashboardConstant } from '../../../variables/constant';
import SpeakingBenchmarkIndicatorPerformanceWidget from './BenchmarkIndicatorWidget/SpeakingBenchmarkIndicator';
import WritingIndicatorPerformanceWidget from './BenchmarkIndicatorWidget/WritingBenchmarkIndicator';
import { BenchmarkType, TeacherBenchmarksDashboardTabs } from '../../../variables/types';
import { StyledTab } from '../BenchmarkSummary/benchmarksummary.styled';
import SelectTeacherStudentFilter from '../../../components/SelectTeacherStudentFilter';
import { useHistory } from 'react-router-dom';

const StudentBenchmarkDashboard = () => {
  const history = useHistory();
  const {
    loading, setLoading,
    areWidgetsLoading, setAreWidgetsLoading,
    dashboardWidgetsDataStatus, setDashboardWidgetsDataStatus,
    isDataMissingInAllWidgets, setIsDataMissingInAllWidgets, 
  } = IndividualStudentBenchmarksDashboardContextInit({ dashboardWidgets: StudentBenchmarkDashboardConstant });
  const theme = useTheme();

  const {
    studentData,
    lastSnowflakeUpdateTimeData,
    getLastSnowflakeUpdateTimeLoading,
    onStudentChange,
    tab,
    selectedBenchmarkType,
    handleSelectedBenchmarkTypes,
    studentList,
    studentListDataLoader,
  } = useStudentBenchmarksDashboard({ setDashboardWidgetsDataStatus, dashboardWidgetsDataStatus });
  const queryParams = new URLSearchParams(history.location.search);
  const linkPage = queryParams.get('linkPage');
  const styles = TeacherBenchmarkDashboardStylesCSS(theme);
  const breadcumbLink = linkPage?.includes('benchmarksummary') ? { label: 'Benchmark Summary', route: `${RoutingPaths.TeacherBenchMarksDashboard}/${TeacherBenchmarksDashboardTabs.BenchmarkSummary}?prevPage=individual` } : { label: 'Progress Scores', route: `${RoutingPaths.TeacherBenchMarksDashboard}/${TeacherBenchmarksDashboardTabs.ProgressScores}?prevPage=individual` };
  const breadcrumb = [
    breadcumbLink,
    {
      label: 'Individual',
      route: `/teacher/student-dashboard/${studentData?.id ?? ''}`,
    }];
  return (
    <IndividualStudentBenchmarksDashboardContext.Provider value={{ loading, setLoading, areWidgetsLoading, setAreWidgetsLoading, dashboardWidgetsDataStatus, setDashboardWidgetsDataStatus, isDataMissingInAllWidgets, setIsDataMissingInAllWidgets }}>
      <Layout padding={0}>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          sx={{ ...styles.dashBoardContainer as SxProps }}
          id="teacher-benchmarks-dashboard"

        >
          <Loader open={loading || getLastSnowflakeUpdateTimeLoading} />
          <Box sx={{ ...styles.dashboard as SxProps, paddingLeft: 5.5, paddingRight: 4, paddingBottom: 3, position: 'sticky' }}>
            <AppBreadcrumbs breadcrumb={breadcrumb} />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box>
                <Typography sx={{
                  color: palette.customBlack.fontBlack,
                  fontSize: '32px',
                  fontWeight: 800,
                  lineHeight: 'normal',
                  letterSpacing: '0.5px',
                  textTransform: 'capitalize',
                }}>
                  {studentData?.name ?? ''}
                </Typography>
                {!!lastSnowflakeUpdateTimeData && !getLastSnowflakeUpdateTimeLoading && <Box marginTop={-0.5} >
                  <Typography variant="toolTipGreyText">
                Last Updated: {
                      toReadableFormat(lastSnowflakeUpdateTimeData, {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        timeZone: 'UTC',
                      }, 
                      'en-US').replace('at', '')} UTC
                  </Typography>
                </Box>}
              </Box>
              <Box>
              </Box>
              <Box sx={{ minWidth: '18%' }}>
                {studentData?.id && (<SelectTeacherStudentFilter
                  students={studentList}
                  onChange={onStudentChange}
                  value={studentData}
                  zIndexPopper={1000}
                  disabled={studentListDataLoader}/>)}
              </Box>
            </Box>
            <Box>
              <ExportToPdf
                benchmarkType={selectedBenchmarkType}
                studentName={studentData?.name ?? ''}
                lastSnowflakeUpdateTimeData={lastSnowflakeUpdateTimeData} />
            </Box>
          </Box>
          <Box sx={{ padding: 4, backgroundColor: '#F7F7F7' }}>
            <Grid container spacing={2} sx={{ position: 'relative' }}>
              <Grid item xs={12} sm={7} md={7} lg={7} >
                <StudentGrowthReportWidget
                  enableTooltip
                  alignTitle
                  tooltipTitle={StudentBenchmarkDashboardConstant.StudentGrowthReportWidget.tooltipText} />
              </Grid>
              <Grid item xs={12} sm={5} md={5} lg={5}>
                <BenchmarkScoresWidget
                  enableTooltip
                  alignTitle
                  tooltipTitle={StudentBenchmarkDashboardConstant.BenchmarkScoresWidget.tooltipText} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between', mt: 1, mb: 1 }}>
                  <Typography variant='font24' sx={{ marginLeft: '22px' }}>Choose 1 Benchmark Type for Information Below</Typography>
                  <Box sx={{ ml: 2, mr: -1 }}>
                    <StyledTabs
                      value={tab}
                      onChange={handleSelectedBenchmarkTypes}
                      textColor="primary"
                      scrollButtons={false}
                      id="benchmark-type-tabs"
                    >
                      {
                        Object.entries(BenchmarkType).map(([tabLabel, tabKey]) => {
                          return (
                            <StyledTab
                              id={`${tabKey as string}-tab-student-benchmark-dashboard`}
                              data-click-id={`${tabKey as string}-tabs`}
                              key={`${tabKey as string}-tab`}
                              classes={{
                                root: `${tabKey as string}`,
                              }}
                              label={(
                                <Typography>
                                  <Typography component="span" variant='title18'>
                                    {tabLabel as string}
                                  </Typography>
                                </Typography>
                              )}

                              disableRipple
                            />
                          );
                        })
                      }
                    </StyledTabs>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} minHeight={540}>
                <SpeakingBenchmarkIndicatorPerformanceWidget
                  enableTooltip
                  alignTitle
                  tooltipTitle={StudentBenchmarkDashboardConstant.SpeakingBenchmarkIndicatorPerformaneWidget.tooltipText}
                  benchmarkType={selectedBenchmarkType}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} minHeight={540}>
                <WritingIndicatorPerformanceWidget
                  enableTooltip
                  alignTitle
                  tooltipTitle={StudentBenchmarkDashboardConstant.WritingBenchmarkIndicatorPerformaneWidget.tooltipText}
                  benchmarkType={selectedBenchmarkType}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Layout >
    </IndividualStudentBenchmarksDashboardContext.Provider>
  );
};
export default StudentBenchmarkDashboard;