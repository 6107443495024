import React from 'react';
import { Box } from '@mui/material';
import { palette } from '../../theme/palette';
import { Area, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import YaxisCustomizeTick from '../OverallBenchmarkIndicatorGraph/YaxisCustomizeTick';
import { dateFormatter, getTicks } from '../AdminGraphs/CommonFunctions';
import { AxisDomain } from '../../variables/types';
import CustomStudentGrowthReportTooltip from './CustomStudentGrowthReportTooltip';
import CustomCursor from './CustomCursor';
import CustomLegend from './CustomLegend';
import { TeacherBenchmarkStudentGrowthReportLegendData } from '../../variables/constant';


interface Props {
  data: any,
  lineKey1: string,
  lineKey2: string,
  xAxisKey: string,
  chartWidth: number,
  chartHeight: number,
  yAxisDomain: Array<number>,
  yAxisTicks: Array<number>,
  sessionStartDate: Date,
  sessionEndDate: Date,
}

const BenchmarkStudentGrowthReportChart = ({ data, lineKey1, lineKey2, xAxisKey, chartWidth, chartHeight, yAxisDomain, yAxisTicks, sessionStartDate, sessionEndDate }: Props) => {
  const ticks = getTicks(sessionStartDate, sessionEndDate);
  const getDateData = (dataKeys: { timestamp: number }) => {
    let keys = dataKeys.timestamp;
    return keys;
  };
  const extractedTimestamps = data.map((item: any) => item.timestamp); // Convert to milliseconds
  const maxTimestamp = Math.max(...extractedTimestamps);
  const maxTick = ticks[ticks.length - 1] + (ticks[1] - ticks[0]);
  const nextGreaterTimestamp = ticks.find(ts => ts > maxTimestamp);
  const areaTicks = ticks[ticks.length - 1];
  const areaChartData = [
    { timestamp: nextGreaterTimestamp!, value: yAxisDomain[yAxisDomain.length - 1] },
    {
      timestamp:
        maxTick, value: yAxisDomain[yAxisDomain.length - 1],
    },
  ];
  const combinedData = nextGreaterTimestamp! < areaTicks ? [...data, ...areaChartData] : data;


  return (
    <Box sx={{ height: '400px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart width={chartWidth} height={chartHeight} margin={{ top: 24, right: 0, left: -20, bottom: 5 }} data={combinedData}>
          <CartesianGrid vertical={false} />
          <Line type="monotone"
            dataKey={lineKey1}
            strokeWidth={3}
            stroke={palette.customBlue.aquaBlue}
            dot={{ r: 6, fill: palette.customBlue.aquaBlue }}
            activeDot={{ r: 8, fill: palette.customBlue.aquaBlue }}
            isAnimationActive={false}
          />
          <Line
            type="monotone"
            dataKey={lineKey2}
            strokeWidth={3}
            stroke={palette.customGreen.fontGreen}
            dot={{ r: 6, fill: palette.customGreen.fontGreen }}
            activeDot={{ r: 8, fill: palette.customGreen.fontGreen }}
            isAnimationActive={false} />

          <XAxis
            dataKey={getDateData}
            tickFormatter={dateFormatter}
            type="number"
            scale='time'
            interval={0}
            domain={[(dataMin: AxisDomain) => dataMin, () => sessionEndDate.getTime()]}
            ticks={ticks}
            axisLine={false}
            tickLine={false}
            allowDuplicatedCategory={false}
            dy={5}
            dx={30}
            style={{ fontSize: '14px', fontWeight: 600, color: palette.customBlack.fontBlack, textTransform: 'uppercase' }}
          />
          <YAxis
            tickLine={false}
            axisLine={false}
            tick={<YaxisCustomizeTick />}
            domain={yAxisDomain}
            ticks={yAxisTicks}
          />
          <Area dataKey="value" stroke="#4B5563" fill="#4B5563" dot={false} activeDot={false} /> 
          <Tooltip
            content={<CustomStudentGrowthReportTooltip />}
            cursor={<CustomCursor />}
            wrapperStyle={{ outline: 'none' }}

          />
          <Legend content={<CustomLegend data={TeacherBenchmarkStudentGrowthReportLegendData} id={'student-growth-report-legend'} />} />
        </ComposedChart>
      </ResponsiveContainer >
    </Box >
  );
};

export default BenchmarkStudentGrowthReportChart;

