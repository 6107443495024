import React from 'react';
import { Backdrop, Box, Card, styled, SxProps, Typography } from '@mui/material';
import { palette } from '../../theme/palette';
import TeacherBenchmarkAnalyticsIcon from '../../components/Icons/TeacherBenchmarkAnalyticsIcon';

interface Props {
  openModal: boolean;
}

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  position: 'absolute',
  zIndex: theme?.zIndex?.drawer - 1,
}));

const WelcomeDashboardModal = ({ openModal }: Props) => {

  return (
    <StyledBackdrop
      open={openModal}
    >
      <Box sx={{ p: 4 } as SxProps}>

        <Card sx={{ p: 4, pb: 5, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection={'column'}>
            <Box display="flex"
              justifyContent="center" sx={{ mt: 4 }}>
              <TeacherBenchmarkAnalyticsIcon />
            </Box>
            <Box width="438px" textAlign="center" sx={{ mt: 2, mb: '16px' }} >
              <Typography variant="font28" sx={{ color: palette.customBlue.primaryBlue }}>{'Hi Teachers! Welcome to your Benchmark Data Dashboard!'}</Typography></Box>
            <Box>
              <Typography variant="sLabel" >
                {'Use the filters above to find the data you need.'}
              </Typography>
            </Box>
          </Box>
        </Card>
      </Box >
    </StyledBackdrop >
  );
};

export default WelcomeDashboardModal;
