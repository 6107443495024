import { useEffect, useState } from 'react';
import { useTeacherBenchmarksDashboardContext } from '../teacherbenchmarks-dashboard-hook';
import { BenchmarkType } from '../../../generated/graphql';
import { trackEvent } from '../../../utils/trackevent';
import { useHistory } from 'react-router-dom';

const useBenchmarkSummary = () => {
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const prevPage = queryParams.get('prevPage');
  const toRestoreFilters = prevPage?.includes('individual');

  const selectTabtoRestore = (benchType: BenchmarkType): number => {
    let tabValue: number = 0;
    if (toRestoreFilters) {
      switch (benchType) {
        case BenchmarkType.Boy:
          tabValue = 0;
          break;
        case BenchmarkType.Moy:
          tabValue = 1;
          break;
        case BenchmarkType.Eoy:
          tabValue = 2;
          break;

        default: tabValue = 0;
      }

    }
    return tabValue as number;
  };
  const { selectedGrades, selectedClasses, selectedSchools, isFilterSelected, setBenchmarkType, benchmarkType } = useTeacherBenchmarksDashboardContext();
  const [selectedBenchmarkType, setSelectedBenchmarkType] = useState<BenchmarkType>(toRestoreFilters ? (benchmarkType ? benchmarkType : BenchmarkType.Boy) : BenchmarkType.Boy);
  const [tab, setTab] = useState<number>(toRestoreFilters ? selectTabtoRestore(benchmarkType ? benchmarkType : BenchmarkType.Boy) : 0);

  useEffect(() => {
    setBenchmarkType?.(selectedBenchmarkType);
  }, [selectedBenchmarkType]);
  useEffect(() => {
    if (toRestoreFilters) {
      setSelectedBenchmarkType?.(benchmarkType ? benchmarkType : BenchmarkType.Boy);
    }
  }, [benchmarkType]);
  const handleSelectedBenchmarkTypes = (_: React.ChangeEvent<{}>, newValue: number) => {

    setTab(newValue);
    switch (newValue) {
      case 0:
        setSelectedBenchmarkType?.(BenchmarkType.Boy);
        trackEvent('tbm_boy', 'benchmarksummarytype_boy_click');
        return;
      case 1:
        setSelectedBenchmarkType?.(BenchmarkType.Moy);
        trackEvent('tbm_moy', 'benchmarksummarytype_moy_click');
        return;
      case 2:
        setSelectedBenchmarkType?.(BenchmarkType.Eoy);
        trackEvent('tbm_eoy', 'benchmarksummarytype_eoy_click');
        return;

      default:
        setSelectedBenchmarkType?.(BenchmarkType.Boy);
        trackEvent('tbm_boy', 'benchmarksummarytype_boy_click');
        return;
    }
  };


  return {
    selectedBenchmarkType, setSelectedBenchmarkType,
    tab, handleSelectedBenchmarkTypes, selectedClasses, selectedGrades, selectedSchools,
    isFilterSelected,
  };
};
export default useBenchmarkSummary;
